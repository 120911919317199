<template>
    <modal name="howtouse-modal"
        :adaptive="true"
        :height="'auto'"
        :clickToClose="false"
        >
        <div class="container-fluid p-0">
            <div class="card" v-if="!isLoad">
                <div class="card-header bg-white border-0">
                    <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    <h1 class="fredoka-font ls-3 fw-700 text-current-eling font-lg mb-0">Elingway</h1>
                </div>
                <div class="card-body">
                    <video class="rounded-lg" width="100%" controls controlsList="nodownload">
                        <source :src="data.type == 1 ? data.path :  data.path" type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="card py-5" v-else>
                <div class="row py-5">
                    <div class="col-12 text-center">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    props: ['data'],
    data() {
        return {
            isLoad: false,
            checkLoad: false,
            process: false,
            media : process.env.VUE_APP_URL_CLOUD,
        }
    },
    created() {
    },
    watch: {
    },
    methods: {
        closeModal(){
            this.$modal.hide('howtouse-modal')
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    ::v-deep .vm--modal {
        width: 90% !important;
        left: 5% !important;
    }
    .scrolled {
        max-height: 200px !important;
    }
}
::v-deep .vm--modal {
    width: 600px;
    height: fit-content !important;
}
.min-vh-50 {
    min-height: 50vh;
}
.btn-outline-current.active {
    color: #fff !important
}
.btn-outline-current:hover {
    color: #fff !important
}
.scrolled {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}
.scrolled::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}

/* Track */
.scrolled::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
.scrolled::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.scrolled::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>