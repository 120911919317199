<template>
    <div class="card border-0 mb-0 rounded-lg overflow-hidden">
        <div v-if="!isLoad" class="card-body p-lg-4 bg-greylight">
            <div class="row justify-content-center mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-lightblue p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/vark.png'" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
                        <h2 class="font-md d-inline-block float-left mb-0 text-grey-900 fw-700"><span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">Welcome to</span>Visual, Auditory, Read/Write, Kinesthetic Test</h2>
                        <!-- <img src="https://via.placeholder.com/250x150.png" alt="icon" class="w250 right-15 top-0 position-absolute d-none d-xl-block"> -->
                    </div>
                </div>
                <div v-if="exp != null && exp.path" class="col-12 mt-4">
                    <video width="100%" height="auto" controls>
                        <source :src="exp.type == 1 ? exp.path :  exp.path" type="video/mp4">
                    </video>
                </div>
                <div v-if="exp != null" class="col-12 mt-1">
                    <div class="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 alert-success">
                        <h2 class="fw-700 font-sm mb-3 mt-1 pl-1 text-success mb-4">Visual, Auditory, Read/Write, Kinesthetic Test</h2>
                        <h4 class="font-xss fw-600 text-grey-600 mb-3 position-relative lh-24">{{exp.description}}</h4>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12" v-if="vark.length">
                    <div v-for="(item, i) in vark" :key="i" class="card w-100 p-3 p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row mb-1">
                            <div class="col-12">
                                <span class="float-left mb-0 text-current fw-700">{{item.title | truncate(30, '...')}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="font-xssss fw-700 text-grey-600 d-block">{{item.description | truncate(50, '...')}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12 text-right">
                                <router-link :to="{name:'GettingStartedVark', params:{idVark: item.id}}" class="px-2 btn btn-current text-white fw-600 text-uppercase font-xssss rounded-lg d-md-inline-block p-1 lh-30 text-center ls-3">{{ item.own_submission ? 'Check Result' : 'Start Test' }}</router-link>
                            </div>
                        </div>
                        <div class="row float-right">
                            <span class="font-xssss fw-600 text-grey-500 d-block mr-1 ">Created By : </span>
                            <small class="mr-3 font-xssss">{{item.created.name}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="card-body p-lg-4 bg-greylight">
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            vark: [],
            exp: {}
        }
    },
    created(){
        this.getData();
    },
    methods:{
        async getData(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/getting-start/vark`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success){
                    this.vark = res.data.data
                    this.exp = res.data.exp
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'VARK !',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => console.error(err.response))
        },
    }
}
</script>
<style scoped>
    .radio-cont input[type='radio'] {
        transform: scale(2.2);
        visibility: hidden;
    }
    .radio-cont input[type='radio']:after {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #d1d3d1;
        content: '';
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='radio']:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: none;
    }
</style>