<template>
    <div class="row">
        <div class="col-12">
            <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="card border-0 mb-0 rounded-lg overflow-hidden">
                                <div class="card-body p-lg-5 p-4 bg-black-08">
                                    <span class="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">Getting Started</span>
                                    <h2 class="fw-700 font-lg d-block lh-4 mb-1 text-white mt-2">Welcome to Elingway</h2>
                                    <p class="font-xsss fw-500 text-grey-100 lh-30 pr-lg-5 mt-3 mr-lg-5">An online platform that promotes standardized language assessment for quality assurance for distance foreign language learning.</p>
                                    <span class="dot ml-2 mr-2 d-inline-block btn-round-xss bg-current"></span>
                                    <span class="font-xssss fw-600 text-grey-500 d-inline-block ml-1">SHINE</span>
                                    <span class="dot ml-2 mr-2 d-inline-block btn-round-xss bg-current"></span>
                                    <span class="font-xssss fw-600 text-grey-500 d-inline-block ml-1">GLORY</span>
                                    <span class="dot ml-2 mr-2 d-inline-block btn-round-xss bg-current"></span>
                                    <span class="font-xssss fw-600 text-grey-500 d-inline-block ml-1">TRIUMPH</span>
                                    <span class="dot ml-2 mr-2 d-inline-block btn-round-xss bg-current"></span>
                                    <span class="font-xssss fw-600 text-grey-500 d-inline-block ml-1">EXCLUSIVE</span>
                                    <div class="clearfix"></div>
                                    <!-- <router-link to="/" class="mt-2 bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-0 p-2 lh-34 text-center ls-3 w200">How To Use</router-link> -->
                                    <button v-if="how.id" @click="openHowToUse()" class="mt-2 bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-0 p-2 lh-34 text-center ls-3 w200">How To Use</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                                <ul class="nav nav-tabs d-flex align-items-center justify-content-between product-info-tab border-bottom-0 pl-2 pr-2">
                                    <li :class="{'active': $route.name == 'GettingStarted'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'GettingStarted'}" :to="{name: 'GettingStarted'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="WIN Analysis">WA</router-link>
                                    </li>
                                    <li :class="{'active': $route.name == 'GettingStartedCefrHome'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'GettingStartedCefrHome'}" :to="{name: 'GettingStartedCefrHome'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="Common European Framework of Reference for Languages">CEFR</router-link>
                                    </li>
                                    <li :class="{'active': $route.name == 'GettingStartedPtHome'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'GettingStartedPtHome'}" :to="{name: 'GettingStartedPtHome'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="Test Your English">TYE</router-link>
                                    </li>
                                    <li :class="{'active': $route.name == 'GettingStartedVarkHome'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'GettingStartedVarkHome'}" :to="{name: 'GettingStartedVarkHome'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="Visual, Auditory, Read/Write, Kinesthetic Test">VARK</router-link>
                                    </li>
                                    <li :class="{'active': $route.name == 'GettingStartedSlaHome'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'GettingStartedSlaHome'}" :to="{name: 'GettingStartedSlaHome'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="5 Stages of Second Language Acquisition">SLA</router-link>
                                    </li>
                                    <li :class="{'active': $route.name == 'GettingStartedMlaHome'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'GettingStartedMlaHome'}" :to="{name: 'GettingStartedMlaHome'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="Pretesting, Embedded testing, Post testing">MLA</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12">
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" v-if="$route.name == 'GettingStarted'">
                                    <WA></WA>
                                </div>
                                <div class="tab-pane fade show active" v-if="$route.name == 'GettingStartedCefrHome'">
                                    <CEFR></CEFR>
                                </div>
                                <div class="tab-pane fade show active" v-if="$route.name == 'GettingStartedPtHome'">
                                    <PT></PT>
                                </div>
                                <div class="tab-pane fade show active" v-if="$route.name == 'GettingStartedVarkHome'">
                                    <VARK></VARK>
                                </div>
                                <div class="tab-pane fade show active" v-if="$route.name == 'GettingStartedSlaHome'">
                                    <SLA></SLA>
                                </div>
                                <div class="tab-pane fade show active" v-if="$route.name == 'GettingStartedMlaHome'">
                                    <PEP></PEP>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HowToUse :data="how"></HowToUse>
    </div> 
</template>

<script>
import CEFR from './Sub/CEFR.vue'
import WA from './Sub/WA.vue'
import PEP from './Sub/PEP.vue'
import PT from './Sub/PT.vue'
import SLA from './Sub/SLA.vue'
import VARK from './Sub/VARK.vue'
import HowToUse from '../../../components/helpers/HowToUse.vue'
import axios from 'axios'
export default {
    components: {
        CEFR,
        WA,
        PEP,
        PT,
        SLA,
        VARK,
        HowToUse
    },
    name: 'GetStarted',
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            how: {
                id: '',
                type: 1,
                path: '',
            }
        }
    },
    created(){
        this.getHowToUse()
    },
    methods: {
        async getHowToUse(){
            await axios.get(`${process.env.VUE_APP_URL_API}/how-to-use`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.data) {
                    this.how = res.data.data
                }
            })
        },
        openHowToUse(){
            this.$modal.show('howtouse-modal')
        },
    }
}
</script>
<style scoped>
.underline{
    /* border-bottom: 5px solid #333; */
    width: 100%;
    height: 4px;
    background-color: #333;
    text-align: center;
    display: block;
}
.text-white {
    color: #fff !important;
}
.text-grey-50{
    color: #adb5bd !important;
}
</style>