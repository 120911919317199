<template>
    <div class="card border-0 mb-0 rounded-lg overflow-hidden">
        <div v-if="!isLoad" class="card-body p-lg-4 bg-greylight">
            <div class="row justify-content-center mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-lightblue p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/pt.png'" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
                        <h2 class="font-md d-inline-block float-left mb-0 text-grey-900 fw-700"><span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">Welcome to</span>Test Your English</h2>
                        <!-- <img src="https://via.placeholder.com/250x150.png" alt="icon" class="w250 right-15 top-0 position-absolute d-none d-xl-block"> -->
                    </div>
                </div>
                <div v-if="exp != null && exp.path" class="col-12 mt-4">
                    <video width="100%" height="auto" controls>
                        <source :src="exp.type == 1 ? exp.path :  exp.path" type="video/mp4">
                    </video>
                </div>
                <div v-if="exp != null" class="col-12 mt-1">
                    <div class="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 alert-success">
                        <h2 class="fw-700 font-sm mb-3 mt-1 pl-1 text-success mb-4">Test Your English</h2>
                        <h4 class="font-xss fw-600 text-grey-600 mb-3 position-relative lh-24">{{exp.description}}</h4>
                    </div>
                </div>
                <div v-if="pt.length" class="col-12 mt-1">
                    <div class="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4">
                        <h2 class="fw-700 font-sm mb-3 mt-1 text-current mb-4">Test Results</h2>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-responsive table-bordered text-center">
                                    <thead>
                                        <tr>
                                            <th>Test</th>
                                            <th>Status</th>
                                            <th>Correct</th>
                                            <th>Pass</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in pt" :key="index">
                                            <td>{{ item.title }}</td>
                                            <td>{{ item.own_submission ? 'Done' : 'Not Done' }}</td>
                                            <td>{{ item.own_submission ? `${item.corect}/${item.quests}` : '' }}</td>
                                            <td v-if="item.own_submission">
                                                <div class="btn bg-success" v-if="item.own_submission.score >= 79"><i class="fas fa-check text-white"></i></div>
                                                <div class="btn bg-danger" v-else><i class="fas fa-times text-white"></i></div>
                                            </td>
                                            <td v-else></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="total_submission >= 5" class="col-12 mt-3">
                    <div class="card w-100 bg-success p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/pt.png'" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
                        <h2 class="font-md d-inline-block float-left mb-0 text-grey-900 fw-700">
                            <span class="font-xss fw-600 text-white d-block mb-1">Based on the results of what has been completed, you are in a level </span>
                            {{ getLevel(pass_submission) }} Level
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12" v-if="pt.length">
                    <div v-for="(item, i) in pt" :key="i" class="card w-100 p-3 p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row mb-1">
                            <div class="col-12">
                                <span class="float-left mb-0 text-current fw-700">{{item.title | truncate(30, '...')}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="font-xssss fw-700 text-grey-600 d-block">{{item.description | truncate(50, '...')}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12 text-right">
                                <router-link :to="{name:'GettingStartedPT', params:{idPT: item.id}}" v-if="item.own_submission" class="px-2 btn bg-success text-white fw-600 text-uppercase font-xssss rounded-lg d-md-inline-block p-1 lh-30 text-center ls-3 mr-2">Result : {{ item.corect }} / {{ item.quests }}</router-link>
                                <router-link v-else :to="{name:'GettingStartedPT', params:{idPT: item.id}}" class=" px-2 btn btn-current text-white fw-600 text-uppercase font-xssss rounded-lg d-md-inline-block p-1 lh-30 text-center ls-3">Start Test</router-link>
                            </div>
                        </div>
                        <div class="row float-right">
                            <span class="font-xssss fw-600 text-grey-500 d-block mr-1 ">Created By : </span>
                            <small class="mr-3 font-xssss">{{item.created.name}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="card-body p-lg-4 bg-greylight">
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            pt: [],
            exp: {},
            total_submission: 0,
            pass_submission: 0
        }
    },
    created(){
        this.getData();
    },
    methods:{
        getLevel(pass) {
            let text = ''
            if(pass >= 5) {
                text = 'Advanced'
            } else if(pass == 4) {
                text = 'Upper-Intermediate'
            } else if(pass == 3) {
                text = 'Intermediate'
            } else if(pass == 2) {
                text = 'Elementary'
            } else if(pass == 1) {
                text = 'Beginner'
            } else if(pass <= 0) {
                text = 'Starter'
            }
            return text
        },
        async getData(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/getting-start/pt`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success){
                    this.pt = res.data.data
                    this.exp = res.data.exp
                    this.total_submission = this.pt.filter(x => x.own_submission != null ).length,
                    this.pass_submission = this.pt.filter(x => x.own_submission != null && x.own_submission.score >= 79 ).length
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }

            }).catch((err) => console.error(err.response))
        },
    }
}
</script>
<style scoped>
    .radio-cont input[type='radio'] {
        transform: scale(2.2);
        visibility: hidden;
    }
    .radio-cont input[type='radio']:after {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #d1d3d1;
        content: '';
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='radio']:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: none;
    }
</style>
