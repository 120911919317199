<template>
    <div class="card border-0 mb-0 rounded-lg overflow-hidden">
        <div v-if="!isLoad" class="card-body p-lg-4 bg-greylight">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="card w-100 bg-lightblue p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/cefr.png'" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
                        <h2 class="font-md d-inline-block float-left mb-0 text-grey-900 fw-700"><span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">Welcome to</span>Common European Framework of Reference for Languages</h2>
                        <!-- <img src="https://via.placeholder.com/250x150.png" alt="icon" class="w250 right-15 top-0 position-absolute d-none d-xl-block"> -->
                    </div>
                </div> 
                <div v-if="data != null" class="col-12 mt-4">
                    <video width="100%" height="auto" controls>
                        <source :src="data.type == 1 ? data.path :  data.path" type="video/mp4">
                    </video>
                </div>
                <div v-if="data != null" class="col-12 mt-4">
                    <div class="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 alert-success">
                        <h2 class="fw-700 font-sm mb-3 mt-1 pl-1 text-success mb-4">Common European Framework of Reference for Languages</h2>
                        <h4 class="font-xss fw-600 text-grey-600 mb-3 position-relative lh-24">{{data.description}}</h4>
                    </div>
                </div>
                <div v-else class="col-12 mt-4 text-center">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
        </div>
        <div v-else class="card-body p-lg-4 bg-greylight">
            <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: []
        }
    },
    created(){
        this.getData();
    },
    methods:{
        async getData(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/getting-start/cefr`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success){
                    this.data = res.data.data
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'CEFR !',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => console.error(err.response))
        },
    }
}
</script>